import React from 'react'
import { Link } from 'gatsby'

const Pagination = ({ pageContext, pathPrefix }) => {
  const { previousPagePath, nextPagePath, pageNumber } = pageContext

  return (
      <nav className="navigation pagination">
          <h2 className="screen-reader-text">Posts navigation</h2>
          <div className="nav-links">
              {previousPagePath && (
              <Link className="prev page-numbers" to={previousPagePath}>Previous</Link>
              )}
              {/*<span className="page-numbers current">*/}
										{/*<span className="meta-nav screen-reader-text">Page </span>1*/}
									{/*</span>*/}
              {/*<a className="page-numbers" href="#"><span*/}
                  {/*className="meta-nav screen-reader-text">Page </span>2</a>*/}
              {/*<a className="page-numbers" href="#"><span*/}
                  {/*className="meta-nav screen-reader-text">Page </span>3</a>*/}
              {/*<a className="page-numbers" href="#"><span*/}
                  {/*className="meta-nav screen-reader-text">Page </span>...</a>*/}
              {/*<a className="page-numbers" href="#"><span*/}
                  {/*className="meta-nav screen-reader-text">Page </span>6</a>*/}
              {nextPagePath && (
              <Link to={nextPagePath} className="next page-numbers" >Next</Link>
              )}
          </div>
      </nav>
  )
}

export default Pagination
