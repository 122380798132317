import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import OwlCarousel from 'react-owl-carousel2';
import '../../node_modules/react-owl-carousel2/lib/styles.css';
import './all.scss';
import { Context } from './Context'

var decode = require('unescape');


export default class FeaturedPosts extends React.Component {
    render() {
        const { posts, title } = this.props
        return (
            <Context.Consumer>
		        {({ lang }) => (
                    <StaticQuery
                        query={graphql`
                            query {
                                allWordpressPost(sort: {order: DESC, fields: date}) {
                                    edges {
                                        node {
                                            id
                                            title
                                            excerpt
                                            acf {
                                                post_type
                                            }
                                            categories {
                                                name
                                                slug
                                            }
                                            better_featured_image {
                                                source_url
                                            }
                                            author {
                                                name
                                                slug
                                                avatar_urls {
                                                    wordpress_48
                                                }
                                            }
                                            date(formatString: "MMMM DD, YYYY")
                                            slug
                                            wordpress_site
                                        }
                                    }
                                }
                            }
                        `}
                        render={data => (
                            <div className="container-fluid no-left-padding no-right-padding slider-section featured-post-slides">
                                <OwlCarousel  
                                    className="owl-carousel carousel slider-carousel-4"
                                    options={{
                                        responsive: {
                                            320: {
                                                items: 1
                                            },
                                            568: {
                                                items: 2
                                            },
                                            768: {
                                                items: 3
                                            },
                                            992: {
                                                items: 4
                                            },
                                            1200:{
                                                items: 4
                                            },
                                            1600:{
                                                items: 4
                                            }
                                        },
                                        rewind: false,
                                        autoplay: false,
                                        margin: 4,
                                        nav: false 
                                    }}>
                                    {data.allWordpressPost.edges.filter(({node: post}) => (post.wordpress_site===lang && post.acf.post_type.includes("Featured")))
                                    .map(({ node: post }, index) => (
                                        (index<10 && (
                                            <div className="post-box"  key={post.id}>
                                                <img src={ post.better_featured_image ?  post.better_featured_image.source_url : "https://placehold.it/477x500"} alt="Slide"/>
                                                <div className="entry-content">
                                                    <span className="post-category"><a href="#" title={decode(post.categories[0].name)}>{decode(post.categories[0].name)}</a></span>
                                                    <h3><Link to={post.wordpress_site + "/" + decodeURI(post.slug) + "/"} title={post.title}><span dangerouslySetInnerHTML={{ __html: post.title }}></span></Link></h3>
                                                    <Link to={post.wordpress_site + "/" + decodeURI(post.slug) + "/"} title="Read more">{lang=='en' ? 'Read more' : 'قراءة المزيد'}</Link>
                                                </div>
                                            </div>
                                        ))
                                    ))}
                                </OwlCarousel>
                            </div>
                        )}
                    />
                )}
            </Context.Consumer>
        );
    }
}