import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostListAr from '../components/PostListAr'
import Pagination from '../components/Pagination'
import FeaturedPosts from '../components/FeaturedPosts'
import PopularPosts from '../components/PopularPosts'
import CategoriesPosts from '../components/CategoriesPosts'
import InstagramSideWidget from '../components/InstagramSideWidget'
import FollowUsSocialSideWidget from '../components/FollowUsSocialSideWidget'
import NewsletterSieWidget from '../components/NewsletterSieWidget'
import TagsSideWidget from '../components/TagsSideWidget'
import Head from '../components/Head'

export default class IndexPage extends React.Component {
  render() {

    const { data, pageContext } = this.props
    const { edges: posts } = data.allWordpressPost
    const seo = {canonical: 'https://blog.halayalla.com/ar',
                  metadesc: ".يمكنك حجز تذاكر الحفلات الموسيقية والفعاليات والمهرجانات اون لاين من خلال منصة هلايلا السعودية  مواسم السعودية موسم جدة و الرياض والشرقية والسوده وغيرهم..",
                  seoTitle: "هلايلا منصة سعودية لادارة الفعاليات والحفلات | حجز التذاكر اونلاين",
                  metakeywords: "تذاكر اونلاين, حجز حفلات, مهرجان, فعاليات, سينما",
                  opengraph_title: "هلايلا منصة سعودية لادارة الفعاليات والحفلات | حجز التذاكر اونلاين",
                  opengraph_description: ".يمكنك حجز تذاكر الحفلات الموسيقية والفعاليات والمهرجانات اون لاين من خلال منصة هلايلا السعودية  مواسم السعودية موسم جدة و الرياض والشرقية والسوده وغيرهم..",
                  opengraph_image : {
                    source_url: "https://blog-test.halayalla.rocks/wp-content/uploads/2020/01/79907400_2723039161249533_7604513295656910987_n.jpg"
                  },
                  twitter_title: "هلايلا منصة سعودية لادارة الفعاليات والحفلات | حجز التذاكر اونلاين",
                  twitter_description: ".يمكنك حجز تذاكر الحفلات الموسيقية والفعاليات والمهرجانات اون لاين من خلال منصة هلايلا السعودية  مواسم السعودية موسم جدة و الرياض والشرقية والسوده وغيرهم..",
                  twitter_image: {
                    source_url: "https://blog-test.halayalla.rocks/wp-content/uploa…907400_2723039161249533_7604513295656910987_n.jpg"
                  }
                }

    return (
      <Layout>
        <Head {...seo}/>
        <FeaturedPosts posts={posts}/>
        <main className="site-main">
              <div className="container-fluid no-left-padding no-right-padding page-content">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-6 content-area">
                              <PostListAr posts={posts} title="Latest posts" />
                              <Pagination pageContext={pageContext} pathPrefix="/" />
                          </div>
                          <div className="col-lg-4 col-md-6 widget-area">
                            <PopularPosts/>
                            <CategoriesPosts/>
                            {/* <InstagramSideWidget/> */}
                            <FollowUsSocialSideWidget/>
                            <NewsletterSieWidget/>
                            <TagsSideWidget/>
                          </div>
                      </div>
                  </div>
              </div>
          </main>
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allWordpressPost(
      sort: { fields: date, order: DESC }
      filter: {wordpress_site: {eq: "ar"}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          acf {
            post_type
        }
          ...PostListFieldAr
        }
      }
    }
  }
`
